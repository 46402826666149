import { IMoloLogoProps } from "../MoloLogoProps";

const TextD = (props: IMoloLogoProps) => {
	return (
		<path
			{...props}
			id="text-d"
			d="M346.355 46.2949C346.355 45.7324 346.52 45.2637 346.848 44.8887C347.188 44.5137 347.691 44.3262 348.359 44.3262C349.027 44.3262 349.531 44.5137 349.871 44.8887C350.223 45.2637 350.398 45.7324 350.398 46.2949C350.398 46.834 350.223 47.2852 349.871 47.6484C349.531 48.0117 349.027 48.1934 348.359 48.1934C347.691 48.1934 347.188 48.0117 346.848 47.6484C346.52 47.2852 346.355 46.834 346.355 46.2949ZM346.373 30.7734C346.373 30.2109 346.537 29.7422 346.865 29.3672C347.205 28.9922 347.709 28.8047 348.377 28.8047C349.045 28.8047 349.549 28.9922 349.889 29.3672C350.24 29.7422 350.416 30.2109 350.416 30.7734C350.416 31.3125 350.24 31.7637 349.889 32.127C349.549 32.4902 349.045 32.6719 348.377 32.6719C347.709 32.6719 347.205 32.4902 346.865 32.127C346.537 31.7637 346.373 31.3125 346.373 30.7734ZM355.689 48V22.4062H362.914C365.141 22.4062 367.109 22.8984 368.82 23.8828C370.531 24.8672 371.85 26.2676 372.775 28.084C373.713 29.9004 374.188 31.9863 374.199 34.3418V35.9766C374.199 38.3906 373.73 40.5059 372.793 42.3223C371.867 44.1387 370.537 45.5332 368.803 46.5059C367.08 47.4785 365.07 47.9766 362.773 48H355.689ZM359.064 25.1836V45.2402H362.615C365.217 45.2402 367.238 44.4316 368.68 42.8145C370.133 41.1973 370.859 38.8945 370.859 35.9062V34.4121C370.859 31.5059 370.174 29.25 368.803 27.6445C367.443 26.0273 365.51 25.207 363.002 25.1836H359.064Z"
		/>
	);
};

export default TextD;
