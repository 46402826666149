import { IMoloLogoProps } from "../MoloLogoProps";

const TextSivut = (props: IMoloLogoProps) => {
	return (
		<path
			{...props}
			id="text-sivut"
			d="M225.512 213.592C222.617 212.76 220.508 211.74 219.184 210.533C217.871 209.314 217.215 207.814 217.215 206.033C217.215 204.018 218.018 202.354 219.623 201.041C221.24 199.717 223.338 199.055 225.916 199.055C227.674 199.055 229.238 199.395 230.609 200.074C231.992 200.754 233.059 201.691 233.809 202.887C234.57 204.082 234.951 205.389 234.951 206.807H231.559C231.559 205.26 231.066 204.047 230.082 203.168C229.098 202.277 227.709 201.832 225.916 201.832C224.252 201.832 222.951 202.201 222.014 202.939C221.088 203.666 220.625 204.68 220.625 205.98C220.625 207.023 221.064 207.908 221.943 208.635C222.834 209.35 224.34 210.006 226.461 210.604C228.594 211.201 230.258 211.863 231.453 212.59C232.66 213.305 233.551 214.143 234.125 215.104C234.711 216.064 235.004 217.195 235.004 218.496C235.004 220.57 234.195 222.234 232.578 223.488C230.961 224.73 228.799 225.352 226.092 225.352C224.334 225.352 222.693 225.018 221.17 224.35C219.646 223.67 218.469 222.744 217.637 221.572C216.816 220.4 216.406 219.07 216.406 217.582H219.799C219.799 219.129 220.367 220.354 221.504 221.256C222.652 222.146 224.182 222.592 226.092 222.592C227.873 222.592 229.238 222.229 230.188 221.502C231.137 220.775 231.611 219.785 231.611 218.531C231.611 217.277 231.172 216.311 230.293 215.631C229.414 214.939 227.82 214.26 225.512 213.592ZM242.967 225H239.592V199.406H242.967V225ZM257.627 220.518L264.939 199.406H268.631L259.139 225H256.15L246.676 199.406H250.35L257.627 220.518ZM290.094 199.406V216.809C290.082 219.223 289.32 221.197 287.809 222.732C286.309 224.268 284.27 225.129 281.691 225.316L280.795 225.352C277.994 225.352 275.762 224.596 274.098 223.084C272.434 221.572 271.59 219.492 271.566 216.844V199.406H274.906V216.738C274.906 218.59 275.416 220.031 276.436 221.062C277.455 222.082 278.908 222.592 280.795 222.592C282.705 222.592 284.164 222.082 285.172 221.062C286.191 220.043 286.701 218.607 286.701 216.756V199.406H290.094ZM313.104 202.184H304.877V225H301.52V202.184H293.311V199.406H313.104V202.184Z"
		/>
	);
};

export default TextSivut;
